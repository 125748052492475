import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './app/dictionary/i18n';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { initializeIcons } from '@fluentui/react/lib/Icons';

import reducer from './app/redux/reducer';

import App from './app/App';
import './index.scss';

const store = createStore(reducer, applyMiddleware(thunk));

initializeIcons()

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Router>
				<App />
			</Router>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);
