export const regularAction = {
	GET_START_DATE: 'ordermaker/getStartDate',
	GET_END_DATE: 'ordermaker/getEndDate',
	CLEAR_AVAILABLE_STUFF: 'ordermaker/clearAvailableStuff',
	ADD_TO_CART: 'ordermaker/addToCart',
	DELETE_FROM_CART: 'ordermaker/deleteFromCart',
	GET_CLIENT_DATA: 'ordermaker/getClientData',
	GET_PAYMENT_DATA: 'ordermaker/getPaymentData',
	CHECK_CONTRACT: 'ordermaker/checkContract',
	RESET_PAYME_DATA: 'ordermaker/reserPaymeData',
	ADD_ORDER: 'ordermaker/addOrder',
	GET_TOTAL_AMOUNT: 'ordermaker/getTotalAmount',
	CLEAR_DATA: 'ordermaker/clearData',
};

export const asyncAction = {
	PENDING_REQUEST: 'pending/request',
	FAIL_REQUEST: 'fail/request',
	DONE_GET_CATEGORIES: 'ordermaker/done/getCategories',
	DONE_GET_AVAILABLE_STUFF: 'ordermaker/done/getAvailableStuff',
	DONE_GET_VERIFY_CARD: 'ordermaker/done/getVerifyCard',
	DONE_GET_BOOKING: 'ordermaker/done/getBooking',
};
