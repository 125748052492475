import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IconButton, PrimaryButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';

import ClientDataModal from './components/ClientDataModal/ClientDataModal';
import PaymentDataModal from './components/PaymentDataModal/PaymentDataModal';
import ContractModal from './components/ContractModal/ContractModal';
import PayModal from './components/PayModal/PayModal';
import ClientData from './components/ClientData/ClientData';
import PaymentData from './components/PaymentData/PaymentData';
import ContractData from './components/ContractData/ContractData';
import { IInitialState } from '../../redux/types';

import style from './Payment.module.scss';

const Payment: React.FC = () => {
	const { t } = useTranslation();

	const { cart, contract } = useSelector((state: IInitialState) => state);

	const [
		isClientModalOpen,
		{ setTrue: showClientModal, setFalse: hideClientModal },
	] = useBoolean(false);

	const [
		isPaymentModalOpen,
		{ setTrue: showPaymentModal, setFalse: hidePaymentModal },
	] = useBoolean(false);

	const [
		isContractModalOpen,
		{ setTrue: showContractModal, setFalse: hideContractModal },
	] = useBoolean(false);

	const [isPayModalOpen, { setTrue: showPayModal, setFalse: hidePayModal }] =
		useBoolean(false);

	return (
		<>
			<div className={style.body}>
				<div className={style.container}>
					<h2>{t('orderMaker.paymentBlock')}</h2>
					<div className={style.row}>
						<div>{t('orderMaker.clientData')}</div>
						<IconButton
							iconProps={{ iconName: 'Edit' }}
							onClick={showClientModal}
						/>
					</div>
					<ClientData />
					<div className={style.row}>
						<div>{t('orderMaker.paymentData')}</div>
						<IconButton
							iconProps={{ iconName: 'Edit' }}
							onClick={showPaymentModal}
						/>
					</div>
					<PaymentData />
				</div>
				<ContractData showContractModal={showContractModal} />
				<div className={style.payBtnContainer}>
					<PrimaryButton
						text={t('orderMaker.payButtonText')}
						className={style.payBtn}
						disabled={!contract || !cart.length}
						onClick={showPayModal}
					/>
				</div>
			</div>
			<ClientDataModal
				isClientModalOpen={isClientModalOpen}
				hideClientModal={hideClientModal}
			/>
			<PaymentDataModal
				isPaymentModalOpen={isPaymentModalOpen}
				hidePaymentModal={hidePaymentModal}
			/>
			<ContractModal
				isContractModalOpen={isContractModalOpen}
				hideContractModal={hideContractModal}
			/>
			<PayModal isPayModalOpen={isPayModalOpen} hidePayModal={hidePayModal} />
		</>
	);
};

export default Payment;
