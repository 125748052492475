import { ICartItem, ICategoriesItem, IDates } from '../redux/types';

export const totalAmount = (
	cart: ICartItem[],
	categories: ICategoriesItem[],
	dates: IDates,
	formatted: boolean
) => {
	const formatter = new Intl.NumberFormat('ru-RU', {
		style: 'currency',
		currency: 'UZS',
	});

	const total = cart.reduce((total: number, item: ICartItem) => {
		const find = categories.find(
			(elem: ICategoriesItem) => elem.id === item.category
		);

		if (find) {
			return total + find.onlinePrice * item.qty;
		}

		return total;
	}, 0);

	if (formatted) {
		return formatter.format(total * dates.datesRange.length);
	}

	return total * dates.datesRange.length;
};
