import React from 'react';
import { useTranslation } from 'react-i18next';

import CharactersViewer from '../../modules/CharactersViewer/CharactersViewer';
import BigButton from '../../modules/BigButton/BigButton';

import style from './Landing.module.scss';

const Landing: React.FC = () => {
	const { t } = useTranslation();

	return (
		<div className={style.body}>
			<div className={style.imgContainer}>
				<CharactersViewer />
			</div>
			<div className={style.textContainer}>
				<h1>{t('landing.title')}</h1>
				<p>{t('landing.description')}</p>
				<div className={style.buttonWrapper}>
					<a href='https://t.me/olenirent' rel="noreferrer" target='_blank'>
						<BigButton
							firstText={t('landing.contactButtonFirstText')}
							secondText={t('landing.contactButtonSecondText')}
						/>
					</a>
				</div>
			</div>
		</div>
	);
};

export default Landing;
