import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@fluentui/react';

import { IInitialState } from '../../../../redux/types';

import style from './ClientData.module.scss';

const ClientData: React.FC = () => {
	const { firstName, lastName, phone, passport, email } = useSelector(
		(state: IInitialState) => state.clientData
	);

	if (!!firstName && !!lastName && !!phone && !!passport && !!email) {
		return (
			<div className={style.body}>
				<div className={style.infoContainer}>
					<Icon iconName='Contact' className={style.icon} />
					{firstName} {lastName}
				</div>
				<div className={style.infoContainer}>
					<Icon iconName='Phone' className={style.icon} />
					{phone}
				</div>
				<div className={style.infoContainer}>
					<Icon iconName='Page' className={style.icon} />
					{passport}
				</div>
				<div className={style.infoContainer}>
					<Icon iconName='Mail' className={style.icon} />
					{email}
				</div>
			</div>
		);
	} else {
		return <></>;
	}
};

export default ClientData;
