import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import { Dialog, DialogType } from '@fluentui/react';

import Header from './components/Header/Header';
import Landing from './pages/Landing/Landing';
import Prices from './pages/Prices/Prices';
import OrderComplete from './pages/OrderComplete/OrderComplete';
import NotFound from './pages/NotFound/NotFound';
import OrderMaker from './pages/OrderMaker/OrderMaker';
import Footer from './components/Footer/Footer';

import style from './App.module.scss';

const App: React.FC = () => {
	const [isModalOpen, setModal] = useState(false);

	const { t } = useTranslation();

	useEffect(() => {
		const date = new Date();

		if (date.getTimezoneOffset() === -300) {
			setModal(true);
		}
	}, []);

	return (
		<div className={style.body}>
			<div>
				<Header />
				<Switch>
					<Route path='/' exact component={Landing} />
					<Route path='/prices' exact component={Prices} />
					<Route path='/order-complete' exact component={OrderComplete} />
					<Route path='/order-maker' exact component={OrderMaker} />
					<Route path='/*' exact component={NotFound} />
				</Switch>
			</div>
			<div>
				<Footer />
			</div>
			<Dialog
				hidden={isModalOpen}
				dialogContentProps={{
					type: DialogType.normal,
					title: t('landing.timezoneErrorHeader'),
					subText: t('landing.timezoneErrorContent'),
				}}
			/>
		</div>
	);
};

export default App;
