import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@fluentui/react';

import { IInitialState } from '../../../../redux/types';

import style from './PaymentData.module.scss';

const PaymentData: React.FC = () => {
	const { card, expDate } = useSelector(
		(state: IInitialState) => state.paymentData
	);

	if (!!card && !!expDate) {
		return (
			<div className={style.body}>
				<div className={style.infoContainer}>
					<Icon iconName='PaymentCard' className={style.icon} />
					{card?.substr(0, 2) + '**********' + card?.substr(-4)}
				</div>
				<div className={style.infoContainer}>
					<Icon iconName='Calendar' className={style.icon} />
					{expDate}
				</div>
			</div>
		);
	} else {
		return <></>;
	}
};

export default PaymentData;
