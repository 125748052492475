import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ru from './ru.json';
import en from './en.json';
import uz from './uz.json';

const resources = {
	RU: {
		translation: ru,
	},
	EN: {
		translation: en,
	},
	UZ: {
		translation: uz,
	},
};

i18n.use(initReactI18next).init({
	resources,
	lng: 'RU',
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
