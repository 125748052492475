import peep1 from './peep-1.svg';
import peep2 from './peep-2.svg';
import peep3 from './peep-3.svg';
import peep4 from './peep-4.svg';
import peep5 from './peep-5.svg';
import peep6 from './peep-6.svg';
import peep7 from './peep-7.svg';
import peep8 from './peep-8.svg';
import peep9 from './peep-9.svg';
import peep10 from './peep-10.svg';
import peep11 from './peep-11.svg';
import peep12 from './peep-12.svg';
import peep13 from './peep-13.svg';
import peep14 from './peep-14.svg';
import peep15 from './peep-15.svg';
import peep16 from './peep-16.svg';
import peep17 from './peep-17.svg';
import peep18 from './peep-18.svg';
import peep19 from './peep-19.svg';
import peep20 from './peep-20.svg';
import peep21 from './peep-21.svg';
import peep22 from './peep-22.svg';
import peep23 from './peep-23.svg';
import peep24 from './peep-24.svg';
import peep25 from './peep-25.svg';
import peep26 from './peep-26.svg';
import peep27 from './peep-27.svg';
import peep28 from './peep-28.svg';
import peep29 from './peep-29.svg';
import peep30 from './peep-30.svg';
import peep31 from './peep-31.svg';
import peep32 from './peep-32.svg';
import peep33 from './peep-33.svg';
import peep34 from './peep-34.svg';
import peep35 from './peep-35.svg';
import peep36 from './peep-36.svg';
import peep37 from './peep-37.svg';
import peep38 from './peep-38.svg';
import peep39 from './peep-39.svg';
import peep40 from './peep-40.svg';
import peep41 from './peep-41.svg';
import peep42 from './peep-42.svg';
import peep43 from './peep-43.svg';
import peep44 from './peep-44.svg';
import peep45 from './peep-45.svg';
import peep46 from './peep-46.svg';
import peep47 from './peep-47.svg';
import peep48 from './peep-48.svg';
import peep49 from './peep-49.svg';
import peep50 from './peep-50.svg';
import peep51 from './peep-51.svg';
import peep52 from './peep-52.svg';
import peep53 from './peep-53.svg';
import peep54 from './peep-54.svg';
import peep55 from './peep-55.svg';
import peep56 from './peep-56.svg';
import peep57 from './peep-57.svg';
import peep58 from './peep-58.svg';
import peep59 from './peep-59.svg';
import peep60 from './peep-60.svg';
import peep61 from './peep-61.svg';
import peep62 from './peep-62.svg';
import peep63 from './peep-63.svg';
import peep64 from './peep-64.svg';
import peep65 from './peep-65.svg';
import peep66 from './peep-66.svg';

export const charactersData = [
	{
		key: 'peep1',
		img: peep1,
	},
	{
		key: 'peep2',
		img: peep2,
	},
	{
		key: 'peep3',
		img: peep3,
	},
	{
		key: 'peep4',
		img: peep4,
	},
	{
		key: 'peep5',
		img: peep5,
	},
	{
		key: 'peep6',
		img: peep6,
	},
	{
		key: 'peep7',
		img: peep7,
	},
	{
		key: 'peep8',
		img: peep8,
	},
	{
		key: 'peep9',
		img: peep9,
	},
	{
		key: 'peep10',
		img: peep10,
	},
	{
		key: 'peep11',
		img: peep11,
	},
	{
		key: 'peep12',
		img: peep12,
	},
	{
		key: 'peep13',
		img: peep13,
	},
	{
		key: 'peep14',
		img: peep14,
	},
	{
		key: 'peep15',
		img: peep15,
	},
	{
		key: 'peep16',
		img: peep16,
	},
	{
		key: 'peep17',
		img: peep17,
	},
	{
		key: 'peep18',
		img: peep18,
	},
	{
		key: 'peep19',
		img: peep19,
	},
	{
		key: 'peep20',
		img: peep20,
	},
	{
		key: 'peep21',
		img: peep21,
	},
	{
		key: 'peep22',
		img: peep22,
	},
	{
		key: 'peep23',
		img: peep23,
	},
	{
		key: 'peep24',
		img: peep24,
	},
	{
		key: 'peep25',
		img: peep25,
	},
	{
		key: 'peep26',
		img: peep26,
	},
	{
		key: 'peep27',
		img: peep27,
	},
	{
		key: 'peep28',
		img: peep28,
	},
	{
		key: 'peep29',
		img: peep29,
	},
	{
		key: 'peep30',
		img: peep30,
	},
	{
		key: 'peep31',
		img: peep31,
	},
	{
		key: 'peep32',
		img: peep32,
	},
	{
		key: 'peep33',
		img: peep33,
	},
	{
		key: 'peep34',
		img: peep34,
	},
	{
		key: 'peep35',
		img: peep35,
	},
	{
		key: 'peep36',
		img: peep36,
	},
	{
		key: 'peep37',
		img: peep37,
	},
	{
		key: 'peep38',
		img: peep38,
	},
	{
		key: 'peep39',
		img: peep39,
	},
	{
		key: 'peep40',
		img: peep40,
	},
	{
		key: 'peep41',
		img: peep41,
	},
	{
		key: 'peep42',
		img: peep42,
	},
	{
		key: 'peep43',
		img: peep43,
	},
	{
		key: 'peep44',
		img: peep44,
	},
	{
		key: 'peep45',
		img: peep45,
	},
	{
		key: 'peep46',
		img: peep46,
	},
	{
		key: 'peep47',
		img: peep47,
	},
	{
		key: 'peep48',
		img: peep48,
	},
	{
		key: 'peep49',
		img: peep49,
	},
	{
		key: 'peep50',
		img: peep50,
	},
	{
		key: 'peep51',
		img: peep51,
	},
	{
		key: 'peep52',
		img: peep52,
	},
	{
		key: 'peep53',
		img: peep53,
	},
	{
		key: 'peep54',
		img: peep54,
	},
	{
		key: 'peep55',
		img: peep55,
	},
	{
		key: 'peep56',
		img: peep56,
	},
	{
		key: 'peep57',
		img: peep57,
	},
	{
		key: 'peep58',
		img: peep58,
	},
	{
		key: 'peep59',
		img: peep59,
	},
	{
		key: 'peep60',
		img: peep60,
	},
	{
		key: 'peep61',
		img: peep61,
	},
  {
		key: 'peep62',
		img: peep62,
	},
  {
		key: 'peep63',
		img: peep63,
	},
  {
		key: 'peep64',
		img: peep64,
	},
  {
		key: 'peep65',
		img: peep65,
	},
  {
		key: 'peep66',
		img: peep66,
	},
];
