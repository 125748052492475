import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Icon, PrimaryButton, TooltipHost } from '@fluentui/react';

import style from './ClientDataModalFooter.module.scss';
import { IModalFooterProps } from './ClientDataModalFooter.types';

const ClientDataModalFooter: React.FC<IModalFooterProps> = ({
	dataName,
	data,
	btnIsDisabled,
	onClick,
}) => {
	const { t } = useTranslation();

	const [isChecked, setIsChecked] = useState(false);

	useEffect(() => {
		const checkLocalStorage = localStorage.getItem(dataName);

		if (checkLocalStorage) {
			setIsChecked(true);
		}
		// eslint-disable-next-line
	}, []);

	const handleClick = () => {
		if (isChecked) {
			localStorage.setItem(dataName, JSON.stringify(data));
		}
		if (!isChecked) {
			localStorage.removeItem(dataName);
		}
		onClick();
	};

	return (
		<div className={style.body}>
			<div className={style.saveData}>
				<Checkbox
					label={t('orderMaker.saveData')}
					onChange={() => setIsChecked(isChecked ? false : true)}
					checked={isChecked}
				/>
				<TooltipHost content={t('orderMaker.saveDataTooltip')}>
					<Icon iconName='Info' className={style.icon} />
				</TooltipHost>
			</div>
			<PrimaryButton
				text={t('orderMaker.addDataButton')}
				disabled={btnIsDisabled}
				onClick={handleClick}
				className={style.button}
			/>
		</div>
	);
};

export default ClientDataModalFooter;
