export const createDatesRange = (dateStart: number, dateEnd: number) => {
	const datesRange: number[] = [];

	for (
		let date = new Date(dateStart);
		date < new Date(dateEnd);
		date.setDate(date.getDate() + 1)
	) {
		datesRange.push(date.getTime());
	}

	return datesRange;
};
