import React, { useEffect, useState } from 'react';

import { charactersData } from '../../images/characters/charactersData';

const CharactersViewer: React.FC = () => {
	const [pictureIndex, setPictureIndex] = useState(0);

	useEffect(() => {
		const index = Number(localStorage.getItem('landingPic'));

		if (index <= 64) {
			setPictureIndex(index + 1);
			localStorage.setItem('landingPic', String(index + 1));
		} else {
			localStorage.setItem('landingPic', String(pictureIndex));
		}
		// eslint-disable-next-line
	}, []);

	return <img src={charactersData[pictureIndex].img} alt='character' />;
};

export default CharactersViewer;
