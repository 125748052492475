import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import LogoImg from '../../images/logo.svg';
import BigButton from '../../modules/BigButton/BigButton';

import style from './Header.module.scss';

const Header: React.FC = () => {
	const [pathname, setPathname] = useState('');

	const history = useHistory();

	const location = useLocation();

	const { t, i18n } = useTranslation();

	useEffect(() => {
		setPathname(location.pathname.split('/')[1]);
	}, [location.pathname]);

	const buttonControl = (page: string, button: JSX.Element) => {
		if (pathname !== page) {
			return button;
		}
	};

	const languageSelector = () => {
		const languageList = ['RU', 'UZ', 'EN'];

		return (
			<div className={style.languageSelector}>
				{languageList.map((lang) => (
					<div
						key={lang}
						onClick={() => i18n.changeLanguage(lang)}
						className={clsx({
							[style.selected]: i18n.language === lang,
							[style.notSelected]: i18n.language !== lang,
						})}>
						{lang}
					</div>
				))}
			</div>
		);
	};

	return (
		<div className={style.body}>
			<div className={style.logoContainer} onClick={() => history.push('/')}>
				<div className={style.logoImg}>
					<img src={LogoImg} alt='logo' />
				</div>
				<div className={style.logoText}>
					OLENI
					<br />
					RENT
				</div>
			</div>
			<div className={style.buttonContainer}>
				{buttonControl(
					'prices',
					<BigButton
						firstText={t('header.priceTopButtonFirstText')}
						secondText={t('header.priceTopButtonSecondText')}
						onClick={() => history.push('/prices')}
					/>
				)}
				{buttonControl(
					'order-maker',
					<div className={style.buttonWrapper}>
						<BigButton
							primary={true}
							firstText={t('header.bookingTopButtonFirstText')}
							secondText={t('header.bookingTopButtonSecondText')}
							onClick={() => history.push('/order-maker')}
						/>
					</div>
				)}
				{languageSelector()}
			</div>
		</div>
	);
};

export default Header;
