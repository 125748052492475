import { ICategoriesItem } from '../redux/types';

export const categoryNameFinder = (
	categoryId: string,
	categories: ICategoriesItem[],
	language: string
) => {
	const find = categories.find(
		(item: ICategoriesItem) => item.id === categoryId
	);

	switch (language) {
		case 'EN':
			return find?.nameEN;
		case 'UZ':
			return find?.nameUZ;
		default:
			return find?.nameRU;
	}
};
