import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { Modal, IconButton } from '@fluentui/react';

import SpinnerLoader from '../../../../modules/SpinnerLoader/SpinnerLoader';
import VirifyCard from '../VirifyCard/VirifyCard';
import { startGetVerifyCard } from '../../../../redux/asyncActions';
import { resetPaymeData } from '../../../../redux/regularActions';
import { IInitialState } from '../../../../redux/types';

import style from './PayModal.module.scss';
import { IPayModalProps } from './PayModal.types';

const PayModal: React.FC<IPayModalProps> = ({
	isPayModalOpen,
	hidePayModal,
}) => {
	const { t } = useTranslation();

	const { paymentData, order, payme, pending } = useSelector(
		(state: IInitialState) => state
	);

	const dispatch = useDispatch();

	useEffect(() => {
		if (isPayModalOpen) {
			const requestBody = {
				card: paymentData.card?.split(' ').join(''),
				expDate: paymentData.expDate?.split(' / ').join(''),
			};

			dispatch(startGetVerifyCard(requestBody));
		}
		// eslint-disable-next-line
	}, [isPayModalOpen]);

	const closeModal = () => {
		dispatch(resetPaymeData());
		hidePayModal();
	};

	const contentControl = () => {
		if (pending) {
			return <SpinnerLoader />;
		}

		return !!payme.token ? (
			<VirifyCard isPayModalOpen={isPayModalOpen} closeModal={closeModal} />
		) : (
			<div className={style.fail}>{t('orderMaker.failText')}</div>
		);
	};

	return (
		<Modal isOpen={isPayModalOpen} containerClassName={style.body}>
			{!!order && <Redirect to='/order-complete' />}
			<div className={style.header}>
				<h2>{t('orderMaker.payHeader')}</h2>
				<IconButton
					iconProps={{ iconName: 'Cancel' }}
					onClick={closeModal}
					disabled={pending}
				/>
			</div>
			<div className={style.container}>{contentControl()}</div>
		</Modal>
	);
};

export default PayModal;
