import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SelectionMode, IColumn, ShimmeredDetailsList } from '@fluentui/react';

import HeaderTitle from '../../modules/HeaderTitle/HeaderTitle';
import { startGetCategories } from '../../redux/asyncActions';
import { pricesColumnHeader } from '../../lib/contstants';
import { ICategoriesItem } from '../../redux/types';
import { IInitialState } from '../../redux/types';

import style from './Prices.module.scss';

const Prices: React.FC = () => {
	const { t, i18n } = useTranslation();

	const dispatch = useDispatch();

	const categoryList = useSelector((state: IInitialState) => state.categories);

	useEffect(() => {
		if (!categoryList.length) {
			dispatch(startGetCategories());
		}
		// eslint-disable-next-line
	}, []);

	const columns: IColumn[] = [
		{
			key: pricesColumnHeader.NAME,
			name: t('prices.tableColumn1'),
			fieldName: pricesColumnHeader.NAME,
			minWidth: 200,
		},
		{
			key: pricesColumnHeader.ONLINE,
			name: t('prices.tableColumn2'),
			fieldName: pricesColumnHeader.ONLINE,
			minWidth: 200,
		},
		{
			key: pricesColumnHeader.OFFLINE,
			name: t('prices.tableColumn3'),
			fieldName: pricesColumnHeader.OFFLINE,
			minWidth: 200,
		},
	];

	const items = () => {
		const selectLang = (item: ICategoriesItem) => {
			switch (i18n.language) {
				case 'EN':
					return item.nameEN;
				case 'UZ':
					return item.nameUZ;
				default:
					return item.nameRU;
			}
		};

		return categoryList.map((item: ICategoriesItem, index: number) => ({
			[pricesColumnHeader.NAME]: selectLang(item),
			[pricesColumnHeader.ONLINE]: item.onlinePrice,
			[pricesColumnHeader.OFFLINE]: item.offlinePrice,
		}));
	};

	return (
		<div className={style.body}>
			<HeaderTitle text={t('prices.title')} />
			<div>{t('prices.description')}</div>
			<ShimmeredDetailsList
				items={items()}
				columns={columns}
				selectionMode={SelectionMode.none}
				enableShimmer={categoryList.length ? false : true}
				className={style.table}
			/>
		</div>
	);
};

export default Prices;
