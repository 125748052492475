import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox, Link } from '@fluentui/react';

import { IInitialState } from '../../../../redux/types';
import { checkContract } from '../../../../redux/regularActions';

import style from './ContractData.module.scss';

const ContractData: React.FC<{ showContractModal: () => void }> = ({
	showContractModal,
}) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { cart, contract } = useSelector((state: IInitialState) => state);

	const { firstName, lastName, phone, passport, email } = useSelector(
		(state: IInitialState) => state.clientData
	);

	const { card, expDate } = useSelector(
		(state: IInitialState) => state.paymentData
	);

	const isDisable = () => {
		if (
			!!firstName &&
			!!lastName &&
			!!phone &&
			!!passport &&
			!!email &&
			!!card &&
			!!expDate &&
			!!cart.length
		) {
			return false;
		}
		return true;
	};

	const handleChecked = () => {
		dispatch(checkContract());
	};

	return (
		<div className={style.body}>
			<div className={style.modalText}>
				<Link underline disabled={isDisable()} onClick={showContractModal}>
					{t('orderMaker.readContract')}
				</Link>
			</div>
			<Checkbox
				label={t('orderMaker.acceptContract')}
				checked={contract}
				onChange={handleChecked}
				disabled={isDisable()}
				className={style.checkbox}
			/>
		</div>
	);
};

export default ContractData;
