import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, IconButton } from '@fluentui/react';
import { useSelector } from 'react-redux';

import { onFormatDate } from '../../../../utils/onFormatDate';
import { totalAmount } from '../../../../utils/totalAmount';
import { categoryNameFinder } from '../../../../utils/categoryNameFinder';
import { ICartItem } from '../../../../redux/types';
import { IInitialState } from '../../../../redux/types';

import style from './ContractModal.module.scss';
import { IContractModalProps } from './ContractModal.types';

const ContractModal: React.FC<IContractModalProps> = ({
	isContractModalOpen,
	hideContractModal,
}) => {
	const { t, i18n } = useTranslation();

	const { categories, cart, dates, clientData } = useSelector(
		(state: IInitialState) => state
	);

	return (
		<Modal
			isOpen={isContractModalOpen}
			onDismiss={hideContractModal}
			containerClassName={style.body}>
			<div className={style.header}>
				<h2>
					{t('orderMaker.contractModalHeader')}{' '}
					{onFormatDate(new Date(dates.dateStart))}
				</h2>
				<IconButton
					iconProps={{ iconName: 'Cancel' }}
					onClick={hideContractModal}
				/>
			</div>
			<div className={style.container}>
				<p>
					{t('contract.section.0.1.text')} {clientData.firstName}{' '}
					{clientData.lastName} {t('contract.section.0.2.text')}
				</p>
				<div className={style.title}>{t('contract.section.1.title')}</div>
				<p>{t('contract.section.1.1.text')}</p>
				<ul>
					{cart.map((item: ICartItem) => (
						<li key={`${item.category}${item.size}`}>
							{categoryNameFinder(item.category, categories, i18n.language)}{' '}
							{item.size}
						</li>
					))}
				</ul>
				<div className={style.title}>{t('contract.section.2.title')}</div>
				<p>
					{t('contract.section.2.1.text')}{' '}
					{totalAmount(cart, categories, dates, true)}
					<br />
					{t('contract.section.2.2.text')}
					<br />
					{t('contract.section.2.3.text')}
					<br />
					{t('contract.section.2.4.text')}
					<br />
					{t('contract.section.2.5.text')}
					<br />
				</p>
				<div className={style.title}>{t('contract.section.3.title')}</div>
				<p>
					{t('contract.section.3.1.text')}
					<br />
					{t('contract.section.3.2.text')}{' '}
					{onFormatDate(new Date(dates.dateStart))} -{' '}
					{onFormatDate(new Date(dates.dateEnd))}
					<br />
					{t('contract.section.3.3.text')}
					<br />
				</p>
				<div className={style.title}>{t('contract.section.4.title')}</div>
				<p>
					{t('contract.section.4.1.text')}
					<br />
					{t('contract.section.4.2.text')}
					<br />
					{t('contract.section.4.3.text')}
					<br />
				</p>
				<div className={style.title}>{t('contract.section.5.title')}</div>
				<p>
					{t('contract.section.5.1.text')}
					<br />
					{t('contract.section.5.2.text')}
					<br />
				</p>
				<div className={style.title}>{t('contract.section.6.title')}</div>
				<p>
					{t('contract.section.6.1.text')}
					<br />
					{t('contract.section.6.2.text')}
					<br />
					{t('contract.section.6.3.text')}
					<br />
					{t('contract.section.6.4.text')}
					<br />
					{t('contract.section.6.5.text')}
					<br />
				</p>
			</div>
		</Modal>
	);
};

export default ContractModal;
