import React from 'react';
import { CompoundButton, IButtonStyles } from '@fluentui/react';

import style from './BigButton.module.scss';
import { IBigButtonProps } from './BigButton.types';

const compoundButtonStyle: IButtonStyles = {
	description: style.buttonInput,
	label: style.buttonInput,
	root: style.buttonOutput,
};

const BigButton: React.FC<IBigButtonProps> = ({ firstText, secondText, onClick, primary }) => {
	return (
		<CompoundButton
			primary={primary}
			secondaryText={secondText}
			styles={compoundButtonStyle}
			onClick={onClick}>
			{firstText}
		</CompoundButton>
	);
};

export default BigButton;
