import { asyncAction } from './constans';
import {
	getCategories,
	getAvailableStuff,
	getVerifyCard,
	getCreateBooking,
} from '../api/api';
import {
	IGetAvailableStuff,
	IGetVerifyCard,
	IGetCreateBooking,
} from '../api/api.types';

import { postRequest } from './postRequest';
import { ICategoriesItem, IPayme } from './types';

// getCategories
const doneGetCategories = (payload: ICategoriesItem[]) => ({
	type: asyncAction.DONE_GET_CATEGORIES,
	payload,
});

export const startGetCategories = () =>
	postRequest(getCategories, {}, doneGetCategories);

// getAvailableStuff
const doneGetAvailableStuff = (payload: IGetAvailableStuff) => ({
	type: asyncAction.DONE_GET_AVAILABLE_STUFF,
	payload,
});

export const startGetAvailableStuff = (body: IGetAvailableStuff) =>
	postRequest(getAvailableStuff, body, doneGetAvailableStuff);

// getVerifyCard
const doneGetVerifyCard = (payload: IPayme) => ({
	type: asyncAction.DONE_GET_VERIFY_CARD,
	payload,
});

export const startGetVerifyCard = (body: IGetVerifyCard) =>
	postRequest(getVerifyCard, body, doneGetVerifyCard);

// getBooking
const doneGetBooking = (payload: string | IPayme) => ({
	type: asyncAction.DONE_GET_BOOKING,
	payload,
});

export const startGetCreateBooking = (body: IGetCreateBooking) =>
	postRequest(getCreateBooking, body, doneGetBooking);
