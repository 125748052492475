export const ruCalendar = {
	days: [
		'Воскресенье',
		'Понедельник',
		'Вторник',
		'Среда',
		'Четверг',
		'Пятница',
		'Суббота',
	],
	shortDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пят', 'Сб'],
	months: [
		'Январь',
		'Февраль',
		'Март',
		'Апрель',
		'Май',
		'Июнь',
		'Июль',
		'Август',
		'Сентябрь',
		'Октябрь',
		'Ноябрь',
		'Декабрь',
	],
	shortMonths: [
		'Янв',
		'Фев',
		'Мар',
		'Апр',
		'Май',
		'Июн',
		'Июл',
		'Авг',
		'Сен',
		'Окт',
		'Ноя',
		'Дек',
	],
	goToToday: 'Выбрать сегодня',
};

export const uzCalendar = {
	days: [
		'Yakshanba',
		'Dushanba',
		'Seshanba',
		'Chorshanba',
		'Payshanba',
		'Juma',
		'Shanba',
	],
	shortDays: ['Ya', 'Du', 'Se', 'Ch', 'Pa', 'Ju', 'Sh'],
	months: [
		'Yanvar',
		'Fevral',
		'Mart',
		'Aprel',
		'May',
		'Iyun',
		'Iyul',
		'Avgust',
		'Sentyabr',
		'Oktyabr',
		'Noyabr',
		'Dekabr',
	],
	shortMonths: [
		'Yan',
		'Fev',
		'Mar',
		'Apr',
		'May',
		'Iyn',
		'Iyl',
		'Avg',
		'Sen',
		'Okt',
		'Noy',
		'Dek',
	],
	goToToday: 'Bugun tanlang',
};
