import React from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';

import style from './SpinnerLoader.module.scss';

const SpinnerLoader = () => {
	return (
		<div className={style.body}>
			<Spinner size={SpinnerSize.large} />
		</div>
	);
};

export default SpinnerLoader;
