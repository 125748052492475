import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useBoolean } from '@fluentui/react-hooks';

import BlockModal from '../BlockModal/BlockModal';

import style from './Block.module.scss';
import { IBlockProps } from './Block.types';

const Block: React.FC<IBlockProps> = ({
	id,
	img,
	nameRU,
	nameUZ,
	nameEN,
	hintRU,
	hintUZ,
	hintEN,
	isDisabled,
}) => {
	const { i18n } = useTranslation();

	const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
		useBoolean(false);

	const languageControl = useCallback(
		(type: string) => {
			switch (i18n.language) {
				case 'EN':
					return type === 'name' ? nameEN : hintEN;
				case 'UZ':
					return type === 'name' ? nameUZ : hintUZ;
				default:
					return type === 'name' ? nameRU : hintRU;
			}
		}, // eslint-disable-next-line
		[i18n.language]
	);

	return (
		<>
			<div
				onClick={isDisabled ? hideModal : showModal}
				className={clsx({
					[style.body]: true,
					[style.isDisabled]: isDisabled,
					[style.isActive]: !isDisabled,
				})}>
				<img src={img} alt='stuff' />
				<div className={style.categoryName}>{languageControl('name')}</div>
			</div>
			<BlockModal
				name={languageControl('name')}
				hint={languageControl('hint')}
				isModalOpen={isModalOpen}
				hideModal={hideModal}
				categoryId={id}
			/>
		</>
	);
};

export default Block;
