import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Dialog, DialogType } from '@fluentui/react';

import instagramImg from '../../images/instagram.svg';
import facebookImg from '../../images/facebook.svg';
import telegramImg from '../../images/telegram.svg';
import { version } from '../../lib/contstants';

import style from './Footer.module.scss';

const Footer: React.FC = () => {
	const [modal, setModal] = useState(true);

	const { t } = useTranslation();

	const date = new Date();

	return (
		<>
			<footer className={style.body}>
				<div className={style.textBlock}>
					<h2>{t('footer.headerRules')}</h2>
					<div>
						<p>{t('footer.textRules1')}</p>
						<p>{t('footer.textRules2')}</p>
					</div>
				</div>
				<div className={style.textBlock}>
					<h2>{t('footer.headerContacts')}</h2>
					<div>
						<p>
							{t('footer.textContacts1.1')}{' '}
							<Link href='tel:+998935682882'>+998 93 568 2882</Link>
							<br />
							{t('footer.textContacts1.2')}
						</p>
						<p>
							{t('footer.textContacts2.1')}{' '}
							<Link href='https://t.me/olenirent' target='_blank'>
								@olenirent
							</Link>
							<br />
							{t('footer.textContacts2.2')}
						</p>
						<p>
							<Link
								href='https://goo.gl/maps/DcUspbhF1cvtrJQN9'
								target='_blank'>
								{t('footer.textContacts3.1')}
							</Link>
							<br />
							{t('footer.textContacts3.2')}
						</p>
					</div>
				</div>
				<div className={style.textBlock}>
					<h2>{t('footer.headerSocial')}</h2>
					<div>
						<div className={style.socialText}>
							<img src={instagramImg} alt='instagram' />
							<Link href='https://instagram.com/olenirent' target='_blank'>
								{t('footer.textSocial1')}
							</Link>
						</div>
						<div className={style.socialText}>
							<img src={facebookImg} alt='facebook' />
							<Link href='https://www.facebook.com/olenirent' target='_blank'>
								{t('footer.textSocial2')}
							</Link>
						</div>
						<div className={style.socialText}>
							<img src={telegramImg} alt='telegram' />
							<Link href='https://t.me/olenirent_bot' target='_blank'>
								{t('footer.textSocial3')}
							</Link>
						</div>
					</div>
				</div>
			</footer>
			<div className={style.credits}>
				<div onClick={() => setModal(false)}>
					OLENI.RENT {date.getUTCFullYear()} {version}
				</div>
				<div className={style.dev}>
					<div className={style.text1}>ARASOLIX</div>
					<Link href='http://arasolix.com/' target='_blank'>
						<div className={style.text2}>{t('footer.textDev')}</div>
					</Link>
				</div>
			</div>
			<Dialog
				hidden={modal}
				onDismiss={() => setModal(true)}
				dialogContentProps={{
					type: DialogType.normal,
					title: t('footer.easterEggTitle'),
					subText: `${t('footer.easterEggText')} ${version}`,
				}}
				modalProps={{
					isBlocking: true,
				}}
			/>
		</>
	);
};

export default Footer;
