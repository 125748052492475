import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { DefaultButton, PrimaryButton } from '@fluentui/react';

import BlockModalItem from '../BlockModalItem/BlockModalItem';
import { IInitialState, IAvailableStuff } from '../../../../redux/types';
import { ICartItem } from '../../../../redux/types';
import { addToCart } from '../../../../redux/regularActions';

import style from './BlockModalContent.module.scss';
import { IBlockModalContentProps } from './BlockModalContent.types';

const BlockModalContent: React.FC<IBlockModalContentProps> = ({
	hint,
	hideModal,
	isModalOpen,
	name,
	categoryId,
}) => {
	const { t } = useTranslation();

	const { availableStuff, cart } = useSelector((state: IInitialState) => state);

	const dispatch = useDispatch();

	const [isHintVisable, setIsHintVisable] = useState(false);
	const [localCart, setLocalCart] = useState<Array<ICartItem>>([]);

	useEffect(() => {
		if (!isModalOpen) {
			setIsHintVisable(false);
			setLocalCart([]);
		}
	}, [isModalOpen]);

	const availableStuffParser = useCallback(() => {
		const list: IAvailableStuff = {};

		Object.keys(availableStuff).forEach((key: string) => {
			if (availableStuff[key].qty !== 0) {
				list[key] = availableStuff[key];
			}
		});

		return list;
	}, [availableStuff]);

	const addToCartBtn = () => {
		const newCart = cart.reduce((newCart: ICartItem[], cartItem) => {
			const index = localCart.findIndex((localItem) => {
				return (
					localItem.category === cartItem.category &&
					localItem.size === cartItem.size
				);
			});

			if (index !== -1) {
				const newObj = Object.assign(localCart[index]);
				newObj.qty += cartItem.qty;
				newCart.push(newObj);
				localCart.splice(index, 1);
			} else {
				newCart.push(cartItem);
			}

			return newCart;
		}, []);

		dispatch(addToCart([...newCart, ...localCart]));
		hideModal();
	};

	return (
		<div className={style.body}>
			<DefaultButton
				className={style.hintBtn}
				text={
					!isHintVisable
						? t('orderMaker.openStuffHint')
						: t('orderMaker.closeStuffHint')
				}
				onClick={() => setIsHintVisable(isHintVisable ? false : true)}
			/>
			{isHintVisable && <div className={style.hint}>{hint}</div>}
			{!!Object.keys(availableStuffParser()).length ? (
				Object.keys(availableStuffParser()).map(
					(key: string, index: number) => (
						<BlockModalItem
							key={key}
							stuff={availableStuff[key]}
							name={name}
							localCart={localCart}
							setLocalCart={setLocalCart}
							categoryId={categoryId}
						/>
					)
				)
			) : (
				<div className={style.notAvailable}>{t('orderMaker.notAvailable')}</div>
			)}
			<PrimaryButton
				text={t('orderMaker.addToCart')}
				onClick={addToCartBtn}
				disabled={!localCart.length}
				className={style.addToCartBtn}
			/>
		</div>
	);
};

export default BlockModalContent;
