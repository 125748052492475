import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, IconButton, TextField, MaskedTextField } from '@fluentui/react';

import { IClientData, IInitialState } from '../../../../redux/types';
import { getClientData } from '../../../../redux/regularActions';
import ClientDataModalFooter from '../ClientDataModalFooter/ClientDataModalFooter';

import style from './ClientDataModal.module.scss';
import { IClientDataModalProps } from './ClientDataModal.types';

const ClientDataModal: React.FC<IClientDataModalProps> = ({
	isClientModalOpen,
	hideClientModal,
}) => {
	const { t } = useTranslation();

	const [clientDataLocal, setClientDataLocal] = useState<IClientData>({
		firstName: '',
		lastName: '',
		phone: '',
		passport: '',
		email: '',
	});

	const { firstName, lastName, phone, passport, email } = clientDataLocal;

	const clientData = useSelector(
		(state: IInitialState) => state.clientData
	);

	const dispatch = useDispatch();

	useEffect(() => {
		const clientDataFromStorage = localStorage.getItem('clientData');

		if (clientDataFromStorage) {
			dispatch(getClientData(JSON.parse(clientDataFromStorage)));
			setClientDataLocal(JSON.parse(clientDataFromStorage));
		} else {
			setClientDataLocal(clientData);
		}
		// eslint-disable-next-line
	}, []);

	const btnIsDisabled = () => {
		if (!!firstName && !!lastName && !!phone && !!passport && !!email) {
			return false;
		}

		return true;
	};

	const addClientData = () => {
		dispatch(getClientData(clientDataLocal));
		hideClientModal();
	};

	return (
		<Modal
			isOpen={isClientModalOpen}
			onDismiss={hideClientModal}
			containerClassName={style.body}>
			<div className={style.header}>
				<h2>{t('orderMaker.clientData')}</h2>
				<IconButton
					iconProps={{ iconName: 'Cancel' }}
					onClick={hideClientModal}
				/>
			</div>
			<div className={style.description}>
				{t('orderMaker.clientDataDescription')}
			</div>
			<TextField
				label={t('orderMaker.clientDataFirstName')}
				className={style.textField}
				value={firstName}
				onChange={(_, value) =>
					setClientDataLocal({ ...clientDataLocal, firstName: value })
				}
			/>
			<TextField
				label={t('orderMaker.clientDataLastName')}
				className={style.textField}
				value={lastName}
				onChange={(_, value) =>
					setClientDataLocal({ ...clientDataLocal, lastName: value })
				}
			/>
			<MaskedTextField
				label={t('orderMaker.clientDataPhone')}
				className={style.textField}
				mask='+(999) 99 - 999 - 99 - 99'
				value={phone}
				onChange={(_, value) =>
					setClientDataLocal({ ...clientDataLocal, phone: value })
				}
			/>
			<TextField
				label={t('orderMaker.clientDataPassport')}
				className={style.textField}
				value={passport}
				onChange={(_, value) =>
					setClientDataLocal({
						...clientDataLocal,
						passport: value?.toUpperCase(),
					})
				}
			/>
			<TextField
				label={t('orderMaker.clientDataEmail')}
				className={style.textField}
				value={email}
				onChange={(_, value) =>
					setClientDataLocal({ ...clientDataLocal, email: value })
				}
			/>
			<ClientDataModalFooter
				dataName='clientData'
				data={clientDataLocal}
				btnIsDisabled={btnIsDisabled()}
				onClick={addClientData}
			/>
		</Modal>
	);
};

export default ClientDataModal;
