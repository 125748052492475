import { regularAction } from './constans';
import { ICartItem, IClientData, IPaymentData } from './types';

export const getStartDate = (payload: number) => ({
	type: regularAction.GET_START_DATE,
	payload,
});

export const getEndDate = (payload: number) => ({
	type: regularAction.GET_END_DATE,
	payload,
});

export const clearAvailableStuff = () => ({
	type: regularAction.CLEAR_AVAILABLE_STUFF,
});

export const addToCart = (payload: ICartItem[]) => ({
	type: regularAction.ADD_TO_CART,
	payload,
});

export const deleteFromCart = (payload: ICartItem[]) => ({
	type: regularAction.DELETE_FROM_CART,
	payload,
});

export const getClientData = (payload: IClientData) => ({
	type: regularAction.GET_CLIENT_DATA,
	payload,
});

export const getPaymentData = (payload: IPaymentData) => ({
	type: regularAction.GET_PAYMENT_DATA,
	payload,
});

export const checkContract = () => ({
	type: regularAction.CHECK_CONTRACT,
});

export const resetPaymeData = () => ({
	type: regularAction.RESET_PAYME_DATA,
});

export const addOrder = (payload: string) => ({
	type: regularAction.ADD_ORDER,
	payload,
});

export const getTotalAmount = (payload: number | string) => ({
	type: regularAction.GET_TOTAL_AMOUNT,
	payload,
});

export const clearData = () => ({
	type: regularAction.CLEAR_DATA,
});
