import { asyncAction } from './constans';

const pendingRequest = () => ({ type: asyncAction.PENDING_REQUEST });
const failRequest = (error: any) => ({ type: asyncAction.FAIL_REQUEST, error });

export const postRequest = (requestAPI: any, body: any, done: any) => {
	return (dispatch: any) => {
		dispatch(pendingRequest());

		requestAPI(body)
			.then((response: any) => {
				dispatch(done(response));
			})
			.catch((error: any) => {
				dispatch(failRequest(error.message));
			});
	};
};
