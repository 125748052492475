import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton } from '@fluentui/react';

import { ICartItem } from '../../redux/types';
import { deleteFromCart, getTotalAmount } from '../../redux/regularActions';
import { categoryNameFinder } from '../../utils/categoryNameFinder';
import { totalAmount } from '../../utils/totalAmount';
import { IInitialState } from '../../redux/types';

import style from './Cart.module.scss';

const Cart: React.FC = () => {
	const { t, i18n } = useTranslation();

	const { cart, categories, dates } = useSelector(
		(state: IInitialState) => state
	);

	const dispatch = useDispatch();

	useEffect(() => {
		const amount = totalAmount(cart, categories, dates, false);
		dispatch(getTotalAmount(amount));
		// eslint-disable-next-line
	}, [cart]);

	const deleteCartItem = (deleteItem: number) => {
		const newCart = cart.reduce(
			(cart: ICartItem[], item: ICartItem, index: number) => {
				if (index !== deleteItem) cart.push(item);
				return cart;
			},
			[]
		);

		dispatch(deleteFromCart(newCart));
	};

	return (
		<div className={style.body}>
			<div className={style.container}>
				<div>
					<h2>{t('orderMaker.cartBlock')}</h2>
					{!cart.length ? (
						<div className={style.emptyText}>{t('orderMaker.emptyCart')}</div>
					) : (
						cart.map((item: ICartItem, index: number) => (
							<div
								className={style.cartItem}
								key={`${item.category}${item.size}`}>
								<div>
									{categoryNameFinder(item.category, categories, i18n.language)}{' '}
									{item.size}
								</div>
								<div>
									{item.qty}
									{t('orderMaker.unit')}
								</div>
								<IconButton
									iconProps={{ iconName: 'Delete' }}
									onClick={() => deleteCartItem(index)}
								/>
							</div>
						))
					)}
				</div>
				<div className={style.totalAmount}>
					<div>{t('orderMaker.totalAmount')}</div>
					<div>{totalAmount(cart, categories, dates, true)}</div>
				</div>
			</div>
		</div>
	);
};

export default Cart;
