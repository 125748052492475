import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Block from './components/Block/Block';
import SpinnerLoader from '../../modules/SpinnerLoader/SpinnerLoader';
import { IInitialState, ICategoriesItem } from '../../redux/types';

import style from './Categories.module.scss';

const Categories: React.FC = () => {
	const { t } = useTranslation();

	const { categories, dates } = useSelector((state: IInitialState) => state);

	const isDisabled = dates.dateStart && dates.dateEnd ? false : true;

	return (
		<div className={style.body}>
			<h2>{t('orderMaker.categoriesBlock')}</h2>
			<div className={style.categoriesContainer}>
				{!categories.length ? (
					<div className={style.spinnerContainer}>
						<SpinnerLoader />
					</div>
				) : (
					categories.map((category: ICategoriesItem) => (
						<Block key={category.id} {...category} isDisabled={isDisabled} />
					))
				)}
			</div>
		</div>
	);
};

export default Categories;
