import React, { useEffect, useState } from 'react';
import { Modal } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@fluentui/react/';

import SpinnerLoader from '../../../../modules/SpinnerLoader/SpinnerLoader';
import BlockModalContent from '../BlockModalContent/BlockModalContent';
import { IInitialState, ICartItem } from '../../../../redux/types';
import { startGetAvailableStuff } from '../../../../redux/asyncActions';
import { clearAvailableStuff } from '../../../../redux/regularActions';

import style from './BlockModal.module.scss';
import { IBlockModalProps } from './BlockModal.types';

const BlockModal: React.FC<IBlockModalProps> = ({
	isModalOpen,
	hideModal,
	name,
	categoryId,
	hint,
}) => {
	const dispatch = useDispatch();

	const { dates, pending, cart } = useSelector((state: IInitialState) => state);

	const [enableSpinner, setEnableSpinner] = useState(true);

	useEffect(() => {
		if (isModalOpen) {
			const cartForRequset = cart.filter(
				(item: ICartItem) => item.category === categoryId
			);
			dispatch(
				startGetAvailableStuff({
					id: categoryId,
					dates: dates.datesRange,
					cart: cartForRequset,
				})
			);
		}
		// eslint-disable-next-line
	}, [isModalOpen]);

	useEffect(() => {
		if (!pending) {
			setEnableSpinner(false);
		}
		if (pending) {
			setEnableSpinner(true);
		}
	}, [pending]);

	const closeModal = () => {
		hideModal();
		dispatch(clearAvailableStuff());
	};

	return (
		<Modal
			isOpen={isModalOpen}
			onDismiss={closeModal}
			isBlocking={false}
			containerClassName={style.body}>
			<div className={style.container}>
				<div className={style.header}>
					<h2>{name}</h2>
					<IconButton iconProps={{ iconName: 'Cancel' }} onClick={closeModal} />
				</div>
				{enableSpinner ? (
					<SpinnerLoader />
				) : (
					<BlockModalContent
						hint={hint}
						hideModal={hideModal}
						isModalOpen={isModalOpen}
						name={name}
						categoryId={categoryId}
					/>
				)}
			</div>
		</Modal>
	);
};

export default BlockModal;
