import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { MaskedTextField, PrimaryButton } from '@fluentui/react';

import { IGetCreateBooking } from '../../../../api/api.types';
import { startGetCreateBooking } from '../../../../redux/asyncActions';
import { IInitialState, ICartItem } from '../../../../redux/types';

import style from './VirifyCard.module.scss';
import { IVirifyCardProps } from './VirifyCard.types';

const VirifyCard: React.FC<IVirifyCardProps> = ({
	isPayModalOpen,
	closeModal,
}) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const [stopWatch, setStopWatch] = useState(60);

	const [code, setCode] = useState<any>('');

	const { dates, payme, amount, clientData, cart } = useSelector(
		(state: IInitialState) => state
	);

	useEffect(() => {
		if (!!stopWatch && isPayModalOpen) {
			setTimeout(() => setStopWatch(stopWatch - 1), 1000);
		}
		if (!isPayModalOpen) {
			setStopWatch(60);
		}
		if (!stopWatch) {
			closeModal();
		}
		// eslint-disable-next-line
	}, [stopWatch, isPayModalOpen, confirm]);

	const handlePay = () => {
		const cartIdList = cart.reduce((arr: string[], item: ICartItem) => {
			arr.push(...item.idList);
			return arr;
		}, []);

		const bodyRequest: IGetCreateBooking = {
			amount: amount,
			cart: cartIdList,
			token: payme.token,
			code: code,
			...dates,
			...clientData,
			author: 'client',
		};

		dispatch(startGetCreateBooking(bodyRequest));
	};

	return (
		<>
			<p>
				{t('orderMaker.paymeWasSentYouCode')} {payme.phone}
			</p>
			<p>
				{t('orderMaker.secondsLeft')} - {stopWatch}
			</p>
			<div className={style.inputWrapper}>
				<MaskedTextField
					label={t('orderMaker.paymeConfirmationCode')}
					inputClassName={style.input}
					className={style.input}
					mask='9 9 9 9 9 9'
					onChange={(_, value) => setCode(value?.split(' ').join(''))}
				/>
			</div>
			<PrimaryButton
				text={t('orderMaker.confirmationButton')}
				onClick={handlePay}
				className={style.button}
			/>
		</>
	);
};

export default VirifyCard;
