import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	DatePicker,
	defaultDatePickerStrings,
	addDays,
	TooltipHost,
	Icon,
} from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';

import { getStartDate, getEndDate } from '../../redux/regularActions';

import { onFormatDate } from '../../utils/onFormatDate';
import { IInitialState } from '../../redux/types';

import style from './DateSelector.module.scss';
import { ruCalendar, uzCalendar } from './localisation';

const DateSelector: React.FC = () => {
	const { t, i18n } = useTranslation();

	const dispatch = useDispatch();

	const { dates } = useSelector((state: IInitialState) => state);

	const today = useConst(new Date(Date.now()));
	const timeControl = new Date().getHours();
	const minStartDate = useConst(addDays(today, timeControl >= 20 ? 1 : 0));
	const minEndDate = useCallback(
		() => addDays(new Date(dates.dateStart) || today, 1),
		// eslint-disable-next-line
		[dates.dateStart]
	);

	const strings = () => {
		switch (i18n.language) {
			case 'RU':
				return ruCalendar;
			case 'UZ':
				return uzCalendar;
			default:
				return defaultDatePickerStrings;
		}
	};

	const onSelectDate = (type: string, date?: Date | null) => {
		const utcHours = date?.getUTCHours();

		const getDatesToStore = (date: number) => {
			if (type === 'start') {
				dispatch(getStartDate(date));
			}
			if (type === 'end') {
				dispatch(getEndDate(date));
			}
		};

		if (utcHours && date && utcHours < 19) {
			const uzDate = Math.abs(19 - utcHours) * 3600000 + date?.getTime();
			getDatesToStore(uzDate);
		}
		if (utcHours && date && utcHours > 19) {
			const uzDate = date?.getTime() - Math.abs(19 - utcHours) * 3600000;
			getDatesToStore(uzDate);
		}
		if (date && utcHours === 0) {
			const uzDate = 19 * 3600000 + date?.getTime();
			getDatesToStore(uzDate);
		}
		if(date && utcHours === 19) {
			getDatesToStore(date.getTime());
		}
	};

	return (
		<div className={style.body}>
			<div className={style.pickerWrapper}>
				<DatePicker
					placeholder={t('orderMaker.calendarPlaceholderStartDate')}
					ariaLabel={t('orderMaker.calendarPlaceholderStartDate')}
					formatDate={onFormatDate}
					strings={strings()}
					className={style.picker}
					showGoToToday={false}
					onSelectDate={(date) => onSelectDate('start', date)}
					minDate={minStartDate}
					value={dates.dateStart ? new Date(dates.dateStart) : undefined}
				/>
				<TooltipHost content={t('orderMaker.tooltipStartDate')}>
					<Icon iconName='Info' className={style.icon} />
				</TooltipHost>
			</div>
			<div className={style.pickerWrapper}>
				<DatePicker
					disabled={!dates.dateStart}
					id='targetEndDate'
					placeholder={t('orderMaker.calendarPlaceholderEndDate')}
					ariaLabel={t('orderMaker.calendarPlaceholderEndDate')}
					formatDate={onFormatDate}
					strings={strings()}
					className={style.picker}
					showGoToToday={false}
					onSelectDate={(date) => onSelectDate('end', date)}
					minDate={minEndDate()}
					value={dates.dateEnd ? new Date(dates.dateEnd) : undefined}
				/>
				<TooltipHost content={t('orderMaker.tooltipEndDate')}>
					<Icon iconName='Info' className={style.icon} />
				</TooltipHost>
			</div>
		</div>
	);
};

export default DateSelector;
