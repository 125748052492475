import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MessageBar, MessageBarType } from '@fluentui/react';

import HeaderTitle from '../../modules/HeaderTitle/HeaderTitle';
import Categories from '../../components/Categories/Categories';
import Cart from '../../components/Cart/Cart';
import Payment from '../../components/Payment/Payment';
import DateSelector from '../../components/DateSelector/DateSelector';
import { startGetCategories } from '../../redux/asyncActions';
import { IInitialState } from '../../redux/types';

import style from './OrderMaker.module.scss';

const OrderMaker: React.FC = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const [warning, serWarning] = useState(true);

	const categories = useSelector((state: IInitialState) => state.categories);

	useEffect(() => {
		if (!categories.length) {
			dispatch(startGetCategories());
		}
		// eslint-disable-next-line
	}, []);

	return (
		<div className={style.body}>
			<HeaderTitle text={t('orderMaker.title')} />
			<DateSelector />
			{warning && (
				<div className={style.remainderContainer}>
					<div>
						<MessageBar
							dismissButtonAriaLabel='Close'
							onDismiss={() => serWarning(false)}
							messageBarType={MessageBarType.severeWarning}
							className={style.remainder}
							style={{ fontSize: '15px' }}>
							{t('orderMaker.warningRemainder')}
						</MessageBar>
					</div>
				</div>
			)}
			<div className={style.container}>
				<Categories />
				<Cart />
				<Payment />
			</div>
		</div>
	);
};

export default OrderMaker;
