import axios from 'axios';
import {
	IGetAvailableStuff,
	IGetVerifyCard,
	IGetCreateBooking,
} from './api.types';

const postRequest = (url: string, body: any) =>
	axios.post(url, body).then((response) => response.data);

// REQUESTS

export const getCategories = () =>
	postRequest(
		'https://us-central1-olenirent-99820.cloudfunctions.net/app/get-data',
		{
			collection: 'categories',
			query: {
				field: 'status',
				value: true,
			},
		}
	);

export const getAvailableStuff = (body: IGetAvailableStuff) =>
	postRequest(
		'https://us-central1-olenirent-99820.cloudfunctions.net/app/get-available-stuff',
		body
	);

export const getVerifyCard = (body: IGetVerifyCard) =>
	postRequest(
		'https://us-central1-olenirent-99820.cloudfunctions.net/app/get-verify-card',
		body
	);

export const getCreateBooking = (body: IGetCreateBooking) =>
	postRequest(
		'https://us-central1-olenirent-99820.cloudfunctions.net/app/get-create-booking',
		body
	);
