import React, { useState } from 'react';
import { Checkbox, IconButton } from '@fluentui/react';

import { ICartItem } from '../../../../redux/types';

import style from './BlockModalItem.module.scss';
import { IBlockModalItemProps } from './BlockModalItem.types';

const BlockModalItem: React.FC<IBlockModalItemProps> = ({
	stuff,
	name,
	localCart,
	setLocalCart,
	categoryId,
}) => {
	const [counter, setCounter] = useState(1);
	const [isChecked, setIsChecked] = useState(false);

	const getSelectedStuffId = (currentCounter: number) => {
		return stuff.idList.reduce((arr: string[], item: string, index: number) => {
			if (index + 1 <= currentCounter) arr.push(item);
			return arr;
		}, []);
	};

	const changeCounterValue = (type: string) => {
		const currentCounter = type === 'plus' ? counter + 1 : counter - 1;

		const newLocalCart = (type: string) =>
			localCart.reduce((arr: ICartItem[], item: ICartItem) => {
				if (item.size === stuff.size) {
					item.qty = currentCounter;
					item.idList = getSelectedStuffId(currentCounter);
					arr.push(item);
				} else arr.push(item);
				return arr;
			}, []);

		if (type === 'minus' && counter !== 1) {
			setCounter(counter - 1);
			setLocalCart(newLocalCart(type));
		}
		if (type === 'plus' && counter < stuff.qty) {
			setCounter(counter + 1);
			setLocalCart(newLocalCart(type));
		}
	};

	const selectItem = () => {
		if (isChecked) {
			setIsChecked(false);

			const newLocalCart = localCart.reduce(
				(arr: ICartItem[], item: ICartItem) => {
					if (item.size !== stuff.size) arr.push(item);
					return arr;
				},
				[]
			);

			setLocalCart(newLocalCart);
		}
		if (!isChecked) {
			setIsChecked(true);

			const cartItemObj: ICartItem = {
				category: categoryId,
				size: stuff.size,
				qty: counter,
				idList: [stuff.idList[0]],
			};

			setLocalCart([...localCart, cartItemObj]);
		}
	};

	return (
		<div className={style.body}>
			<div className={style.name}>
				<Checkbox
					checked={isChecked}
					onChange={selectItem}
					label={`${name} ${stuff.size}`}
				/>
			</div>
			<div className={style.counter}>
				<IconButton
					primary={true}
					iconProps={{ iconName: 'ChromeMinimize' }}
					onClick={() => changeCounterValue('minus')}
					disabled={counter === 1 || !isChecked}
					className={style.iconButton}
				/>
				<div className={style.number}>{counter}</div>
				<IconButton
					primary
					iconProps={{ iconName: 'Add' }}
					onClick={() => changeCounterValue('plus')}
					disabled={counter === stuff.qty || !isChecked}
					className={style.iconButton}
				/>
			</div>
		</div>
	);
};

export default BlockModalItem;
