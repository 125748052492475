import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
	Modal,
	IconButton,
	Link,
	MaskedTextField,
	PrimaryButton,
} from '@fluentui/react';

import paymeImg from '../../../../images/paymeLogo.svg';
import { IPaymentData } from '../../../../redux/types';
import { getPaymentData } from '../../../../redux/regularActions';

import style from './PaymentDataModal.module.scss';
import { IPaymentDataModalProps } from './PaymentDataModal.types';

const PaymentDataModal: React.FC<IPaymentDataModalProps> = ({
	isPaymentModalOpen,
	hidePaymentModal,
}) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const [payment, setPayment] = useState<IPaymentData>({
		card: '',
		expDate: '',
	});

	const isDisabledButton = () => {
		if (
			payment.card !== '____ ____ ____ ____' &&
			payment.card !== '' &&
			payment.expDate !== '__ / __' &&
			payment.expDate !== ''
		) {
			return false;
		}

		return true;
	};

	const addPaymentData = () => {
		dispatch(getPaymentData(payment));
		hidePaymentModal();
		setPayment({ card: '', expDate: '' });
	};

	return (
		<Modal
			isOpen={isPaymentModalOpen}
			onDismiss={hidePaymentModal}
			containerClassName={style.body}>
			<div className={style.header}>
				<h2>{t('orderMaker.paymentData')}</h2>
				<IconButton
					iconProps={{ iconName: 'Cancel' }}
					onClick={hidePaymentModal}
				/>
			</div>
			<div className={style.description}>
				{t('orderMaker.paymentDataDescription')}{' '}
				<Link href='https://cdn.payme.uz/terms/main.html' target='_blank'>
					{t('orderMaker.paymentDataOfferLink')}
				</Link>
				<div>
					<img src={paymeImg} alt='payme logo' />
				</div>
			</div>
			<div className={style.textFieldContainer}>
				<MaskedTextField
					label={t('orderMaker.paymentDataCardNumber')}
					mask='9999 9999 9999 9999'
					className={style.textFieldCard}
					inputClassName={style.input}
					onChange={(_, val) => setPayment({ ...payment, card: val })}
					value={payment.card}
				/>
				<MaskedTextField
					label={t('orderMaker.paymentDataExpDate')}
					mask='99 / 99'
					className={style.textFieldExpDate}
					inputClassName={style.input}
					onChange={(_, val) => setPayment({ ...payment, expDate: val })}
					value={payment.expDate}
				/>
			</div>
			<div className={style.addDataButtonContainer}>
				<PrimaryButton
					text={t('orderMaker.addDataButton')}
					onClick={addPaymentData}
					disabled={isDisabledButton()}
					className={style.addDataButton}
				/>
			</div>
		</Modal>
	);
};

export default PaymentDataModal;
