import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router';

import CharactersViewer from '../../modules/CharactersViewer/CharactersViewer';
import BigButton from '../../modules/BigButton/BigButton';
import { clearData } from '../../redux/regularActions';
import { IInitialState } from '../../redux/types';

import style from './OrderComplete.module.scss';

const OrderComplete: React.FC = () => {
	const { t } = useTranslation();

	const order = useSelector((state: IInitialState) => state.order);

	const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			dispatch(clearData());
		};
		// eslint-disable-next-line
	}, []);

	return (
		<div className={style.body}>
			{!order && <Redirect to='/' />}
			<div className={style.imgContainer}>
				<CharactersViewer />
			</div>
			<div className={style.textContainer}>
				<h1>{t('orderComplete.title')}</h1>
				<h1>
					{t('orderComplete.orderNumber')} {order}
				</h1>
				<p>{t('orderComplete.description')}</p>
				<div className={style.buttonWrapper}>
					<a href='https://t.me/olenirent' rel='noreferrer' target='_blank'>
						<BigButton
							firstText={t('orderComplete.contactButtonFirstText')}
							secondText={t('orderComplete.contactButtonSecondText')}
						/>
					</a>
				</div>
			</div>
		</div>
	);
};

export default OrderComplete;
