import { IInitialState } from './types';
import { asyncAction, regularAction } from './constans';
import { createDatesRange } from '../utils/createDatesRange';

const initialState: IInitialState = {
	categories: [],
	dates: {
		dateStart: 0,
		dateEnd: 0,
		datesRange: [],
	},
	availableStuff: {},
	cart: [],
	amount: 0,
	clientData: {
		firstName: '',
		lastName: '',
		phone: '',
		passport: '',
		email: '',
	},
	paymentData: {
		card: '',
		expDate: '',
	},
	payme: {
		token: undefined,
		phone: undefined,
		unavailable: '',
	},
	order: '',
	contract: false,
	pending: false,
	error: null,
};

const reducer = (state = initialState, action: any): IInitialState => {
	switch (action.type) {
		// ASYNC
		case asyncAction.DONE_GET_CATEGORIES:
			return {
				...state,
				categories: action.payload.data,
				pending: false,
			};
		case asyncAction.DONE_GET_AVAILABLE_STUFF:
			return {
				...state,
				availableStuff: action.payload,
				pending: false,
			};
		case asyncAction.DONE_GET_VERIFY_CARD:
			return {
				...state,
				payme: {
					...action.payload,
				},
				pending: false,
			};
		case asyncAction.DONE_GET_BOOKING:
			if (action.payload.token === false) {
				return {
					...state,
					payme: {
						...action.payload,
						unavailable: '',
					},
					pending: false,
				};
			}

			return {
				...state,
				order: action.payload,
			};
		case asyncAction.PENDING_REQUEST:
			return {
				...state,
				pending: true,
			};
		case asyncAction.FAIL_REQUEST:
			return {
				...state,
				error: action.error,
			};

		// REGULAR
		case regularAction.GET_START_DATE:
			return {
				...state,
				cart: [],
				dates: {
					...state.dates,
					dateStart: action.payload,
					dateEnd: 0,
				},
			};
		case regularAction.GET_END_DATE:
			return {
				...state,
				cart: [],
				dates: {
					...state.dates,
					dateEnd: action.payload,
					datesRange: createDatesRange(state.dates.dateStart, action.payload),
				},
			};
		case regularAction.CLEAR_AVAILABLE_STUFF:
			return {
				...state,
				availableStuff: {},
			};
		case regularAction.ADD_TO_CART:
			return {
				...state,
				cart: action.payload,
			};
		case regularAction.DELETE_FROM_CART:
			return {
				...state,
				cart: action.payload,
			};
		case regularAction.GET_CLIENT_DATA:
			return {
				...state,
				clientData: action.payload,
			};
		case regularAction.GET_PAYMENT_DATA:
			return {
				...state,
				paymentData: action.payload,
			};
		case regularAction.CHECK_CONTRACT:
			return {
				...state,
				contract: state.contract ? false : true,
			};
		case regularAction.RESET_PAYME_DATA:
			return {
				...state,
				payme: {
					token: undefined,
					phone: undefined,
					unavailable: '',
				},
			};
		case regularAction.ADD_ORDER:
			return {
				...state,
				order: action.payload,
			};
		case regularAction.GET_TOTAL_AMOUNT:
			return {
				...state,
				amount: action.payload,
			};
		case regularAction.CLEAR_DATA:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default reducer;
