import React from 'react';

import style from './HeaderTitle.module.scss';
import { IHeaderTitleProps } from './HeaderTitle.types';

const HeaderTitle: React.FC<IHeaderTitleProps> = ({ text }) => {
	return (
		<div className={style.body}>
			<h1>{text}</h1>
		</div>
	);
};

export default HeaderTitle;
